var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    {
                      attrs: {
                        title: "Asset Inquiry",
                        loading: _vm.loadingDetailHistory
                      }
                    },
                    [
                      _c(
                        "a-tabs",
                        {
                          attrs: {
                            "default-active-key": _vm.currentTab,
                            activeKey: _vm.currentTab,
                            type: "card"
                          },
                          on: { change: _vm.changeMenu }
                        },
                        [
                          _c(
                            "a-tab-pane",
                            {
                              key: "Depreciation",
                              staticStyle: { padding: "1rem" },
                              attrs: { tab: "Depreciation" }
                            },
                            [
                              _c("OpenDepreciation", {
                                attrs: {
                                  dataAssetHistory: _vm.dataAssetHistory,
                                  currentTab: _vm.currentTab
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-tab-pane",
                            {
                              key: "Cost History",
                              staticStyle: { padding: "1rem" },
                              attrs: { tab: "Cost History" }
                            },
                            [
                              _c("OpenCost", {
                                attrs: {
                                  dataAssetHistory: _vm.dataAssetHistory,
                                  currentTab: _vm.currentTab
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-tab-pane",
                            {
                              key: "Detail History",
                              staticStyle: { padding: "1rem" },
                              attrs: { tab: "Detail History" }
                            },
                            [
                              _c("OpenDetails", {
                                attrs: {
                                  dataAssetHistory: _vm.dataAssetHistory,
                                  currentTab: _vm.currentTab
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-tab-pane",
                            {
                              key: "Source",
                              staticStyle: { padding: "1rem" },
                              attrs: { tab: "Source" }
                            },
                            [
                              _c("OpenSourceLine", {
                                attrs: {
                                  dataAssetHistory: _vm.dataAssetHistory,
                                  currentTab: _vm.currentTab
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-tab-pane",
                            {
                              key: "Asset Details",
                              staticStyle: { padding: "1rem" },
                              attrs: { tab: "Asset Details" }
                            },
                            [
                              _c("OpenAssetDetails", {
                                attrs: {
                                  dataAssetHistory: _vm.dataAssetHistory,
                                  currentTab: _vm.currentTab,
                                  id: _vm.id
                                },
                                on: {
                                  getListHistoryAsset: _vm.getListHistoryAsset
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }