




































































import { ResponseGetDetailInquiry } from "@/models/interface/assets.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { assetsServices } from "@/services/assets.service";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      id: "" as string,
      currentTab: "Depreciation" as string,
      loadingDetailHistory: false as boolean,
      dataAssetHistory: {} as ResponseGetDetailInquiry,
    };
  },
  methods: {
    getListHistoryAsset() {
      let params = {
        limit: 10,
        page: 0,
      } as RequestQueryParamsModel;
      this.loadingDetailHistory = true;
      assetsServices
        .getDetailAssetInquiry(params, this.id)
        .then(data => {
          this.dataAssetHistory = data;
        })
        .finally(() => (this.loadingDetailHistory = false));
    },
    changeMenu(currentTabMenu) {
      this.currentTab = currentTabMenu;
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getListHistoryAsset();
  },
});
